@use "../mixins/mx";

.profiles {
  :where(.text-content) {
    @include mx.medium-gap-column;
  }
  :where(ul) {
    @include mx.unstyled-list;
    display: grid;
    row-gap: var(--gap-large);
    @include mx.media-sm {
      grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
      column-gap: var(--space-large);
    }
  }
}
.profiles__card-image-wrapper {
  max-inline-size: 200px;
  margin-inline-end: 1em;
  display: grid;
  grid-template-areas: "a";
  aspect-ratio: 1;
  @include mx.media-md {
    max-inline-size: 100%;
  }
  :where(img) {
    inline-size: 100%;
    aspect-ratio: 1;
    border-radius: 50%;
    object-fit: cover;
  }
  & > * {
    grid-area: a;
  }
}
.profiles__card {
  position: relative; // for whole-card pseudoelement
  @include mx.flex-column;
  gap: var(--gap-large);
  @include mx.media-md {
    max-inline-size: 320px;
  }
  &:has(summary):hover {
    :where(h3) {
      text-decoration-color: var(--theme-color-tint);
    }
    :where(svg) {
      color: var(--theme-color-tint);
    }
  }
  :where(details) {
    padding-block-end: var(--space-small);
    border-block-end: var(--divider-line);
  }
  :where(summary) {
    margin-block-end: var(--space-xsmall);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--space-xsmall);
    cursor: pointer;
    // hide summary marker
    &::-webkit-details-marker,
    &::marker {
      display: none;
    }
    ::after {
      position: absolute;
      content: "";
      inset: 0;
    }
  }
  :where(header) {
    & > * {
      font-size: var(--font-size-small);
    }
    @include mx.flex-column;
  }
  :where(h3) {
    color: var(--theme-color-small-text);
    text-decoration: underline;
    text-underline-offset: var(--underline-offset-medium, 0.2em);
    text-decoration-thickness: var(--underline-thickness-medium, 0.1em);
    text-decoration-color: transparent;
    transition: var(--quick-fade) text-decoration-color;
  }
  :where(svg) {
    inline-size: 1em;
    color: var(--theme-color-primary);
    transition: transform 150ms ease-in-out;
    transform: rotate(0deg);
  }
  :where(details[open] svg) {
    transform: rotate(-180deg);
  }
}

.profiles__initial-image {
  opacity: 1;
  transition: opacity 150ms linear;

  :where(.profiles__card:has(summary):hover, .profiles__card:has(details[open]))
    & {
    opacity: 0;
  }
}
