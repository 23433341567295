@use "../mixins/mx";

.jumplinks {
  @include mx.flex-column;
  gap: var(--space-small);

  :where(h2) {
    @include mx.h4;
    color: var(--theme-color-small-text);
  }
  :where(ul) {
    @include mx.unstyled-list;
    @include mx.flex-column;
    gap: var(--space-xsmall);
  }
  :where(a) {
    display: flex;
    column-gap: var(--space-xsmall);
    align-items: flex-start;
  }
  :where(svg) {
    margin-block-start: 0.3em;
    inline-size: 1em;
    aspect-ratio: 1;
    flex-shrink: 0;
    color: var(--theme-color-primary);
  }
  :where(a) {
    color: var(--color-text);
    text-decoration-color: transparent;
    font-size: var(--font-size-xsmall);
    &:hover {
      color: var(--theme-color-small-text);
      text-decoration-color: var(--theme-color-primary);
    }
  }
}
