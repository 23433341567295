@use "../sass-variables/var";
@use "../functions/fn";

:root {
  --content-max-width: 1512px;
  --max-line-length: 900px;
  --page-gutter: #{fn.generate-clamp(var.$sm, var.$xxxl, 24, 200)};

  --space-xsmall: #{fn.generate-clamp(var.$sm, var.$lg, 8, 12)};
  --space-small: #{fn.generate-clamp(var.$sm, var.$lg, 12, 16)};
  --space-medium: #{fn.generate-clamp(var.$sm, var.$lg, 16, 24)};
  --space-large: #{fn.generate-clamp(var.$sm, var.$lg, 24, 40)};
  --space-xlarge: #{fn.generate-clamp(var.$sm, var.$lg, 40, 64)};

  --gap-small: #{fn.generate-clamp(var.$sm, var.$lg, 12, 20)};
  --gap-medium: #{fn.generate-clamp(var.$sm, var.$lg, 20, 32)};
  --gap-large: #{fn.generate-clamp(var.$sm, var.$lg, 32, 48)};
  --gap-xlarge: #{fn.generate-clamp(var.$lg, var.$xxxl, 64, 120)};

  --divider-line: 1px solid var(--color-grey);
}
