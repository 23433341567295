@use "../mixins/mx";

.table-block {
  // overriding the usual bottom padding and bottom border
  // when applied to the table, it just looks like there's an extra empty row
  padding-block-end: 0;
  border-block-end: none;
  @include mx.flex-column;
  gap: var(--space-medium);
  overflow-x: hidden;

  :where(.table-scroll-wrapper) {
    overflow-x: auto;
  }
  :where(h3) {
    margin-block-end: var(--space-xsmall);
  }
  :where(p) {
    font-size: var(--font-size-xsmall);
  }

  :where(table) {
    inline-size: 100%;
    border-spacing: 0;
    border-collapse: separate;
    max-inline-size: var(--max-line-length);
  }

  :where(th, td) {
    text-align: start;
    vertical-align: top;
    padding: var(--space-xsmall);
    border-block-end: 1px solid var(--theme-color-primary);
  }
  :where(th[scope="row"]) {
    font-weight: 300;
    font-variation-settings: "wght" var(--font-weight-light);
  }
}
