@use "../mixins/mx";

.testimonial {
  :where(figure) {
    display: grid;
    grid-row-gap: var(--gap-large);
    @include mx.media-md {
      grid-template-columns: auto 1fr;
    }
  }

  :where(svg) {
    block-size: var(--font-size-xlarge);
    aspect-ratio: 96 / 76;
    justify-self: start;
  }

  :where(blockquote) {
    @include mx.h2;
    @include mx.media-md {
      grid-row: 2;
      grid-column: 2;
    }
  }

  :where(figcaption) {
    justify-self: end;
    @include mx.media-md {
      grid-column: 2;
      grid-row: 3;
    }
  }
}
