@use "../sass-variables/var";
@use "../functions/fn";

@mixin media-sm {
  @media (min-width: #{fn.em(var.$sm)}) {
    @content;
  }
}
@mixin media-md {
  @media (min-width: #{fn.em(var.$md)}) {
    @content;
  }
}
@mixin media-lg {
  @media (min-width: #{fn.em(var.$lg)}) {
    @content;
  }
}
@mixin media-xl {
  @media (min-width: #{fn.em(var.$xl)}) {
    @content;
  }
}
@mixin media-xxl {
  @media (min-width: #{fn.em(var.$xxl)}) {
    @content;
  }
}

// a very short mixin! only useful due to its frequemcy
@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin medium-gap-column {
  @include flex-column;
  gap: var(--gap-medium);
}

@mixin content-width {
  inline-size: 100%;
  margin-inline: auto;
  padding-inline: var(--page-gutter);
  max-inline-size: calc(var(--content-max-width) + var(--page-gutter) * 2);
}

@mixin h1 {
  font-size: var(--font-size-xlarge);
  line-height: var(--line-height-tight);
  font-weight: 800;
  font-variation-settings: "wght" var(--font-weight-h1);
}
@mixin h2 {
  font-size: var(--font-size-large);
  line-height: var(--line-height-tight);
  font-weight: bold;
  font-variation-settings: "wght" var(--font-weight-bold);
}
@mixin h3 {
  font-size: var(--font-size-medium);
  line-height: var(--line-height-tight);
  font-weight: bold;
  font-variation-settings: "wght" var(--font-weight-bold);
}
@mixin h4 {
  font-size: var(--font-size-small);
  line-height: var(--line-height-tight);
  font-weight: bold;
  font-variation-settings: "wght" var(--font-weight-bold);
}

@mixin link-style {
  color: var(--theme-color-small-text, var(--color-blue--contrast-45));
  font-variation-settings: "wght" var(--font-weight-medium);
  text-decoration-line: underline;
  text-decoration-style: solid;
  // the text underline styles have fallbacks for round() support (available since May 2024)
  text-decoration-thickness: var(--underline-thickness-thin, 0.05em);
  text-underline-offset: var(--underline-offset-small, 0.2em);
  transition: var(--quick-symmetrical);
  &:hover {
    text-decoration-thickness: var(--underline-thickness-thin, 0.1em);
    text-underline-offset: var(--underline-offset-medium, 0.2em);
    color: var(--theme-color-tint, var(--color-blue--tint));
  }
  outline-color: var(--color-blue);
  outline-offset: 0.2em;
}

@mixin unstyled-list {
  list-style: none;
  padding-inline-start: 0;
}

@mixin unstyled-button {
  border: 0;
  background: transparent;
  appearance: none;
  padding: 0;
  cursor: pointer;
}

@mixin hide-summary-marker {
  &::-webkit-details-marker,
  &::marker {
    display: none;
  }
  cursor: pointer;
}
