@use "../mixins/mx";

body {
  font-size: var(--font-size-small);
  color: var(--color-text);
  line-height: var(--line-height-loose);
}
:where(h1) {
  @include mx.h1;
}
:where(h2) {
  @include mx.h2;
}
:where(h3) {
  @include mx.h3;
}

:where(a) {
  @include mx.link-style;
}
:where(strong) {
  strong {
    font-variation-settings: "wght" var(--font-weight-bold);
  }
}
