@use "../mixins/mx";
@use "../sass-variables/var";
@use "../functions/fn";

.footer {
  --footer-column-gap: #{fn.generate-clamp(var.$md, var.$lg, 16, 40)};
  --footer-link-padding: 4px;
  margin-block-start: var(--space-medium);
  font-size: var(--font-size-xsmall);
}
.footer__content {
  background-color: var(--theme-color-small-text);
  color: white;
  padding-inline: var(--page-gutter);
  padding-block: var(--space-large);

  @include mx.media-md {
    margin-inline: var(--page-gutter);
    margin-block-end: var(--space-large);
    border-radius: var(--space-xsmall);
    padding-inline: var(--space-large);
    padding-block: var(--space-medium);
    display: flex;
    justify-content: space-between;
    gap: var(--footer-column-gap);
  }

  :where(h2) {
    margin-block-end: var(--space-small);
    @include mx.flex-column;
    font-size: var(--font-size-medium);

    :last-child {
      font-weight: 300;
      font-variation-settings: "wght" var(--font-weight-light);
    }
  }
}

.footer__details {
  @include mx.flex-column;
  gap: var(--space-small);

  @include mx.media-md {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: var(--footer-column-gap);
  }
  :where(a) {
    padding-block: var(--footer-link-padding);
    color: white;
    text-decoration-color: transparent;
    font-weight: 700;
    font-variation-settings: "wght" var(--font-weight-bold);
    &:hover {
      text-decoration-color: white;
      text-decoration-thickness: var(--underline-thickness-medium, 2px);
    }
  }
}

.footer__two-liner {
  grid-column: 1;
  :first-child {
    font-weight: 700;
    font-variation-settings: "wght" var(--font-weight-bold);
  }
  :last-child {
    font-weight: 300;
    font-variation-settings: "wght" var(--font-weight-light);
  }
}

.footer__links {
  grid-column: 2;
  grid-row: 1;
  @include mx.flex-column;
  margin-block: calc(-1 * var(--footer-link-padding));
}
