@use "../mixins/mx";

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--font-family);
}

main {
  @include mx.content-width;
  // main will go to `position: fixed` and the footer will be hidden when the
  // mobile menu is open - in order to avoid any visible change, we make `main`
  // full-height, ensuring that the footer is not visible when activating the menu
  min-block-size: 100vh;
  @include mx.media-lg {
    min-block-size: unset;
  }
}

:where(figure) {
  @include mx.flex-column;
  gap: var(--space-xsmall);
}
:where(img) {
  inline-size: 100%;
  border-radius: var(--space-xsmall);
}
:where(figcaption) {
  font-size: var(--font-size-xsmall);
  font-weight: 500;
  font-variation-settings: "wght" var(--font-weight-medium);
  color: var(--color-text--tint);
}
