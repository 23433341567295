@use "sass:map";
@use "sass:meta";
@use "sass:string";
@use "sass:math";
// =============================================================================
// Functions
// =============================================================================

// Used by `rem` function
@function strip-unit($number) {
  @if meta.type-of($number) == "number" and not math.is-unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

// Convert pixels to rems
@function rem($size) {
  $rem-size: math.div(strip-unit($size), strip-unit(16));
  @return #{$rem-size}rem;
}

// Convert pixels to ems
// (USE FOR BREAKPOINTS ONLY)
@function em($size) {
  @return math.div(strip-unit($size), strip-unit(16)) * 1em;
}

// Generate responsive clamp() functions

// Clamp function using viewport width & px/rem units.
// Accepts `rem` or `rem` for $unit, defaults to px in -> px out.
@function generate-clamp(
  $start-bp,
  $end-bp,
  $start-val,
  $end-val,
  $unit: null
) {
  @return calculate-clamp(
    (
      start-bp: $start-bp,
      end-bp: $end-bp,
      start-val: $start-val,
      end-val: $end-val,
      unit: $unit,
      responsive-unit: "vw",
    )
  );
}

// Clamp function using container width & px/rem units.
// Accepts `rem` or `rem` for $unit, defaults to px in -> px out.
@function generate-clamp-cq(
  $start-bp,
  $end-bp,
  $start-val,
  $end-val,
  $unit: null
) {
  @return calculate-clamp(
    (
      start-bp: $start-bp,
      end-bp: $end-bp,
      start-val: $start-val,
      end-val: $end-val,
      unit: $unit,
      responsive-unit: "cqi",
    )
  );
}

// Clamp function using viewport width & unitless values
// Only use with fallbacks / as a progressive enhancement until support for @property improves
@function generate-clamp-unitless($start-bp, $end-bp, $start-val, $end-val) {
  @return calculate-clamp(
    (
      start-bp: $start-bp,
      end-bp: $end-bp,
      start-val: $start-val,
      end-val: $end-val,
      unit: "unitless",
      responsive-unit: "vw",
    )
  );
}

// Fancy maths and fixes to get 1vw as a pixel value, without the unit:
// https://dev.to/janeori/css-type-casting-to-numeric-tanatan2-scalars-582j
@property --1vw-in-px {
  syntax: "<length>";
  initial-value: 0px;
  inherits: false;
}
:root {
  // setting 1vw to a property which we specified will be px prevents it being set to the incorrect values of 0 or 100: https://dev.to/janeori/css-type-casting-to-numeric-tanatan2-scalars-582j#screensize
  --1vw-in-px: 1vw;
  --1vw-in-px-unitless: tan(atan2(var(--1vw-in-px), 1px));
}

// the chunky function doing all the work behind the single-use wrapper functions
@function calculate-clamp($args) {
  $start-bp: map.get($args, start-bp);
  $end-bp: map.get($args, end-bp);
  $start-val: map.get($args, start-val);
  $end-val: map.get($args, end-val);
  $unit: map.get($args, unit);
  $responsive-unit: map.get($args, responsive-unit);

  $start-val-px: 0;
  $end-val-px: 0;
  $output-unit: "px";

  @if $unit == rem {
    $start-val-px: $start-val * 16;
    $end-val-px: $end-val * 16;
    $output-unit: "rem";
  } @else if $unit == rem {
    $start-val-px: $start-val;
    $end-val-px: $end-val;
    $output-unit: "rem";
  } @else if $unit == unitless {
    $start-val-px: $start-val;
    $end-val-px: $end-val;
    $output-unit: "";
  } @else {
    $start-val-px: $start-val;
    $end-val-px: $end-val;
    $output-unit: "px";
  }

  $slope: math.div($end-val-px - $start-val-px, $end-bp - $start-bp);
  $intercept: $start-val-px - ($slope * $start-bp);

  $start-val-final: $start-val-px;
  $intercept-final: $intercept;
  $end-val-final: $end-val-px;

  @if $unit == rem or $unit == rem {
    $start-val-final: math.div($start-val-px, 16);
    $intercept-final: math.div($intercept, 16);
    $end-val-final: math.div($end-val-px, 16);
  }

  $responsive-var: if(
    $responsive-unit == "vw",
    "--1vw-in-px-unitless",
    "--1cqi-in-px-unitless"
  );

  @if $unit == unitless {
    @return string.unquote(
      "clamp(" + "#{min($start-val, $end-val)},"+"#{$intercept_final} + #{$slope * 100} * var(#{$responsive-var}),"+
        "#{max($start-val, $end-val)}"+")"
    );
  } @else {
    @return clamp(
      #{$start-val-final}#{$output-unit},
      calc(
        #{$intercept-final}#{$output-unit} + #{$slope * 100}#{$responsive-unit}
      ),
      #{$end-val-final}#{$output-unit}
    );
  }
}
