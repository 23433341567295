.btn {
  inline-size: fit-content;
  font-weight: 500;
  font-variation-settings: "wght" var(--font-weight-medium);
  color: var(--color-text);
  text-decoration: underline;
  text-decoration-color: var(--theme-color-primary);
  text-underline-offset: var(--underline-offset-large, 0.55em);
  text-decoration-thickness: var(--underline-thickness-thick, 0.45em);
  padding-block-end: 1em; // underline offset + thickness

  &:hover {
    text-underline-offset: var(--underline-offset-xlarge, 0.65em);
    color: var(--theme-color-small-text);
  }
}
