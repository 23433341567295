/*
Largely based on Josh W Comeau's reset (https://www.joshwcomeau.com/css/custom-css-reset/),
with a few changes and additions.
*/

/*
  Use a more-intuitive box-sizing model.
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html,
body {
  /*
    Allow percentage-based heights in the application
  */
  block-size: 100%;
}

html {
  /*
  Stop unexpected font size growth:
  https://kilianvalkhof.com/2022/css-html/your-css-reset-needs-text-size-adjust-probably/
  */
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

body {
  -webkit-font-smoothing: antialiased;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-inline-size: 100%;
}

img {
  block-size: auto;
}

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
/*
  Create a root stacking context (selectors may not be applicable to your project)
*/
#root,
#__next {
  isolation: isolate;
}
