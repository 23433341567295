:root {
  --color-grey: #a7b0b4;
  --color-text: #1d4b5e; // oklch(38.99% 0.059 228.37)
  --color-text--tint: #48768a; // oklch(53.99% 0.059 228.37)
  --color-blue: #0088c7; // oklch(59.7% 0.135 239.5)
  --color-blue--tint: #38a5e6; // oklch(69% 0.135 239.5)
  // each theme needs options with a contrast ratio of 3:1 (large text) and 4.5:1 (small text)
  --color-blue--contrast-30: var(--color-blue);
  --color-blue--contrast-45: #097cb5; // oklch(56% 0.125 239.5)
  --color-green: #008a43; // oklch(55.44% 0.148 151.4)
  --color-green--tint: #39a960; // oklch(65.44% 0.148 151.4)
  --color-green--contrast-30: var(--color-green);
  --color-green--contrast-45: #068843; // oklch(55% 0.145 151.4)
  --color-yellow: #fdc824; //oklch(84.62% 0.17 85.29)
  --color-yellow--tint: #ffe3a9; // oklch(92.5% 0.08 85.29)
  // the following murky sludge is why the yellow theme is not an option for text:
  --color-yellow--contrast-30: #bb8e0e; // oklch(67.25% 0.135 85.29)
  --color-yellow--contrast-45: #977103; // oklch(56.99% 0.116 84.79)
  --color-orange: #f58220; // oklch(72.22% 0.171 53.92)
  --color-orange--tint: #fdb17f; // oklch(82.22% 0.171 53.92)
  --color-orange--contrast-30: #e67502; // oklch(68% 0.17 53.92)
  --color-orange--contrast-45: #bb5e04; // oklch(58.35% 0.145 53.92)
  // red is legible, but too alarming for text use
  --color-red: #d71921; // oklch(56.07% 0.22 26.79)
  --color-red--tint: #fd4641; // oklch(66.07% 0.22 26.79)
  --color-red--pale: #feaba1; // oklch(82% 0.1 26.79)
  --color-red--contrast-30: var(--color-red);
  --color-red--contrast-45: var(--color-red);
  --color-pink: #f05c74; // oklch(67.27% 0.182 13.73)
  --color-pink--tint: #fb909b; // oklch(77% 0.13 13.73)
  --color-pink--contrast-30: var(--color-pink);
  --color-pink--contrast-45: #d2415d; // oklch(59% 0.18 13.73)
  --color-purple: #825397; // oklch(52.19% 0.116 314.74)
  --color-purple--tint: #b07fc6; // oklch(67% 0.116 314.74)
  --color-purple--muted: #ac8eba; // oklch(68.9% 0.072 315)
  --color-purple--pale: #eecaff; // oklch(88.7% 0.08 314.74)
  --color-purple--shade: #643678; //oklch(42% 0.116 314.74)
  --color-purple--contrast-30: var(--color-purple);
  --color-purple--contrast-45: var(--color-purple);
}
