@use "../mixins/mx";

.block {
  padding-block: var(--space-xlarge);
  border-block-end: var(--divider-line);
  &:last-child {
    border-block-end: none;
  }

  // stacked on small screens, equal columns on large screens
  display: grid;
  gap: var(--gap-large);
  @include mx.media-md {
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  }
  &:where(.full-width) {
    @include mx.media-md {
      // overriding the usual 2-column layout
      grid-template-columns: 1fr;
    }
  }

  :where(h2, h3) {
    color: var(--theme-color-large-text);
    scroll-margin-block-start: var(--space-xlarge);
  }
}
