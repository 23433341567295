.theme--blue {
  --theme-color-primary: var(--color-blue);
  --theme-color-tint: var(--color-blue--tint);
  --theme-color-small-text: var(--color-blue--contrast-45);
  --theme-color-large-text: var(--color-blue--contrast-30);
}
.theme--green {
  --theme-color-primary: var(--color-green);
  --theme-color-tint: var(--color-green--tint);
  --theme-color-small-text: var(--color-green--contrast-45);
  --theme-color-large-text: var(--color-green--contrast-30);
}
.theme--yellow {
  --theme-color-primary: var(--color-yellow);
  --theme-color-tint: var(--color-yellow--tint);
  --theme-color-small-text: var(--color-yellow--contrast-45);
  --theme-color-large-text: var(--color-yellow--contrast-30);
}
.theme--orange {
  --theme-color-primary: var(--color-orange);
  --theme-color-tint: var(--color-orange--tint);
  --theme-color-small-text: var(--color-orange--contrast-45);
  --theme-color-large-text: var(--color-orange--contrast-30);
}
.theme--red {
  --theme-color-primary: var(--color-red);
  --theme-color-tint: var(--color-red--tint);
  --theme-color-small-text: var(--color-red--contrast-45);
  --theme-color-large-text: var(--color-red--contrast-30);
}
.theme--pink {
  --theme-color-primary: var(--color-pink);
  --theme-color-tint: var(--color-pink--tint);
  --theme-color-small-text: var(--color-pink--contrast-45);
  --theme-color-large-text: var(--color-pink--contrast-30);
}
.theme--purple {
  --theme-color-primary: var(--color-purple);
  --theme-color-tint: var(--color-purple--tint);
  --theme-color-small-text: var(--color-purple--contrast-45);
  --theme-color-large-text: var(--color-purple--contrast-30);
}
