@use "../sass-variables/var";
@use "../functions/fn";

:root {
  // fallbacks from https://github.com/system-fonts/modern-font-stacks?tab=readme-ov-file#rounded-sans
  --font-family: "Mulish", ui-rounded, "Hiragino Maru Gothic ProN", Quicksand,
    Comfortaa, Manjari, "Arial Rounded MT", "Arial Rounded MT Bold", Calibri,
    source-sans-pro, sans-serif;

  --font-size-xsmall: #{fn.generate-clamp(var.$sm, var.$md, 14, 16, px2rem)};
  --font-size-small: #{fn.generate-clamp(var.$sm, var.$md, 18, 20, px2rem)};
  --font-size-medium: #{fn.generate-clamp(var.$sm, var.$md, 20, 24, px2rem)};
  --font-size-large: #{fn.generate-clamp(var.$sm, var.$md, 24, 36, px2rem)};
  --font-size-xlarge: #{fn.generate-clamp(var.$sm, var.$lg, 32, 48, px2rem)};
  --font-size-xxlarge: #{fn.generate-clamp(var.$sm, var.$lg, 40, 64, px2rem)};

  --line-height-tight: 1.1;
  --line-height-medium: 1.3;
  --line-height-loose: 1.5;

  --font-weight-extrabold: #{fn.generate-clamp-unitless(
      var.$sm,
      var.$xxl,
      850,
      1000
    )};
  --font-weight-h1: #{fn.generate-clamp-unitless(var.$sm, var.$xxl, 750, 950)};
  --font-weight-bold: #{fn.generate-clamp-unitless(var.$sm, var.$xxl, 600, 800)};
  --font-weight-medium: #{fn.generate-clamp-unitless(
      var.$sm,
      var.$xxl,
      500,
      650
    )};
  --font-weight-regular: #{fn.generate-clamp-unitless(
      var.$sm,
      var.$xxl,
      450,
      400
    )};
  --font-weight-light: #{fn.generate-clamp-unitless(var.$sm, var.$xxl, 350, 250)};

  // use of these variables need fallbacks for round() support
  --underline-thickness-thin: round(0.05em, 1px);
  --underline-thickness-medium: round(0.1em, 1px);
  --underline-thickness-thick: round(0.45em, 1px);

  --underline-offset-small: round(0.2em, 1px);
  --underline-offset-medium: round(0.25em, 1px);
  --underline-offset-large: round(0.55em, 1px);
  --underline-offset-xlarge: round(0.65em, 1px);
}
