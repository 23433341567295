@use "../mixins/mx";
@use "../sass-variables/var";
@use "../functions/fn";

.header {
  --menu-icon-size: calc(var(--font-size-small) * var(--line-height-loose));
  --top-nav-height: #{fn.generate-clamp(var.$sm, var.$lg, 4, 6, rem)};
  --top-nav-padding: calc((var(--top-nav-height) - var(--menu-icon-size)) / 2);
  @include mx.content-width;
  block-size: var(--top-nav-height);

  :where(nav) {
    display: flex;
    justify-content: space-between;
    & > :where(a) {
      padding: var(--top-nav-padding);
      outline-offset: -4px;
    }
  }

  :where(a:focus-visible) {
    outline: var(--color-blue) 2px solid;
    outline-offset: 0.2em;
    border-radius: 2px;
  }
}

.logo__k {
  color: var(--color-blue);
}
.logo__i {
  color: var(--color-green);
}
.logo__d {
  color: var(--color-yellow);
}
.logo__z {
  color: var(--color-orange);
}
.logo__o {
  color: var(--color-red);
}
.logo__n {
  color: var(--color-pink);
}
.logo__e {
  color: var(--color-purple);
}
.header__logo {
  block-size: var(--menu-icon-size);
  text-decoration: none;
  :where(header:has(.mobile-menu--is-open)) & > path {
    color: white;
  }
  :where(.mobile-menu--is-open .header__logo-link:hover) & > path {
    color: var(--color-purple--pale);
  }
}
.header__logo-link {
  margin-inline-start: calc(-1 * var(--top-nav-padding));
  border-radius: 8px;
  transition: opacity 100ms linear;

  &:where(button) {
    @include mx.unstyled-button;
    padding: var(--top-nav-padding);
  }
  &:hover {
    opacity: 0.8;
  }
  &:focus-visible {
    outline-offset: -4px;
  }
}
