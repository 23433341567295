@use "../mixins/mx";

.desktop-menu {
  display: none;
  @include mx.media-lg {
    display: flex;
    @include mx.unstyled-list;
    column-gap: var(--space-medium);
    padding-block: var(--top-nav-padding);
    flex-wrap: wrap;
  }
  :where(a) {
    font-size: var(--font-size-xsmall);
  }
}
