@use "../mixins/mx";

// if there's only one image
.image :where(figure:only-child img) {
  object-fit: cover;
  aspect-ratio: 4/3;
  @include mx.media-lg {
    aspect-ratio: 16 / 9;
  }
}
