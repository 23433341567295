@use "../mixins/mx";

.hero {
  :where(.text-content) {
    @include mx.medium-gap-column;
  }

  :where(h1) {
    color: var(--theme-color-large-text);
  }

  :where(img) {
    inline-size: 100%;
  }
}
.hero__homepage-heading {
  font-size: var(--font-size-xxlarge);
  font-weight: 900;
  font-variation-settings: "wght" var(--font-weight-extrabold);
}
