@font-face {
  font-family: "Mulish";
  src: url("Mulish-Variable.woff2") format("woff2-variations");
  font-weight: 250 1000;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mulish";
  src: url("Mulish-Italic-Variable.woff2") format("woff2-variations");
  font-weight: 250 1000;
  font-style: italic;
  font-display: swap;
}
