@use "../mixins/mx";

.contact-form {
  :where(form) {
    gap: var(--space-medium);
    @include mx.flex-column;
    max-inline-size: var(--max-line-length);
  }
  :where(label) {
    font-size: var(--font-size-xs);
    font-weight: 500;
    font-variation-settings: "wght" var(--font-weight-medium);
    @include mx.flex-column;
  }
  :where(input, textarea) {
    appearance: none;
    background: none;
    resize: none;

    margin-block-end: var(--space-small);
    border-radius: 4px;
    border: 1px solid var(--color-grey);
    padding-block: var(--space-xsmall);
    padding-inline: var(--space-small);
    color: var(--color-text);

    &:where(:user-invalid) {
      background-color: var(--color-red--pale);
      outline: 2px solid var(--color-red);
    }
    &:focus {
      outline: 2px solid var(--color-blue);
      background-color: transparent;
    }
  }

  :where(label):has(:where(input, textarea):where(:user-invalid)) {
    position: relative;
    &::after {
      content: "This field is required";
      position: absolute;
      inset-block-end: -0.75em;
      inset-inline-start: 0;
      color: var(--color-red);
      font-size: var(--font-size-xsmall);
    }
  }

  :where(input) {
    line-height: 1;
    height: 2.25em;
  }

  :where(button) {
    margin-block-start: var(--space-large);
    border-radius: var(--space-xsmall);
    color: white;
    font: inherit;
    font-weight: 700;
    font-variation-settings: "wght" var(--font-weight-bold);

    @include mx.unstyled-button;
    & {
      border: 2px solid var(--theme-color-large-text);
      background-color: var(--theme-color-large-text);
      padding-block: var(--space-xsmall);
      padding-inline: var(--space-medium);
    }

    @include mx.media-md {
      align-self: flex-start;
    }
    &:hover {
      background-color: var(--theme-color-tint);
    }
    &:disabled {
      border-color: var(--color-grey);
      background-color: var(--color-grey);
    }
    &.success {
      border-color: var(--color-purple--muted);
      background-color: var(--color-purple--muted);
    }
  }
}
