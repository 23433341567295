@use "../mixins/mx";

.map {
  //
}

:where(.map__container) {
  border-radius: var(--space-xsmall);
  inline-size: 100%;
  aspect-ratio: 4/3;
  @include mx.media-lg {
    aspect-ratio: 16 / 9;
  }
}

:where(.mapbox-improve-map) {
  display: none;
}
:where(.mapboxgl-ctrl) {
  font-family: var(--font-family);
}
:where(.marker) {
  // background-image: url(/images/map-marker.svg);
  // background-size: cover;
  inline-size: 48px;
  aspect-ratio: 1/1;
  cursor: pointer;
}
