@use "../mixins/mx";

.mobile-menu {
  @include mx.media-lg {
    display: none;
  }

  :where(ul) {
    @include mx.unstyled-list;
  }
}
:where(body):has(.mobile-menu--is-open) {
  :where(main) {
    position: fixed;
  }
  // when main is fixed, the footer would float to the top and be visible
  :where(footer) {
    visibility: hidden;
  }

  // mobile menu has slight transparency and the contents below it are blurred.
  // backdrop-filter is not currently supported on webkit, so here's a fallback:
  :where(main) {
    filter: blur(4px) grayscale(100%);
    @supports (backdrop-filter: blur(3px)) {
      filter: unset;
    }
  }
}

.mobile-menu__menu-button {
  margin-inline-end: calc(-1 * var(--top-nav-padding));
  aspect-ratio: 1;

  display: flex;
  @include mx.unstyled-button;
  & {
    padding: var(--top-nav-padding);
    border-width: 2px;
  }
  &:focus-visible {
    outline: var(--color-blue) 2px solid;
    outline-offset: -2px;
    border-radius: 2px;
  }
}

.mobile-menu__hamburger {
  inline-size: var(--menu-icon-size);
  aspect-ratio: 1;
  color: var(--color-purple);
  :where(a, button):hover & {
    color: var(--color-purple--tint);
    :where(.mobile-menu--is-open) & {
      color: var(--color-purple--pale);
    }
  }
  :where(path) {
    transition: 300ms transform ease-in-out, 300ms color linear;
  }

  .mobile-menu__top-bun {
    transform: rotate(0) scaleX(1);
  }
  .mobile-menu__patty {
    transform: scaleX(1);
  }
  .mobile-menu__bottom-bun {
    transform: rotate(0) scaleX(1);
  }

  :where(.mobile-menu--is-open) & {
    color: white;
    .mobile-menu__top-bun {
      transform: rotate(45deg) scaleX(1.3);
    }
    .mobile-menu__patty {
      transform: scaleX(0.1);
    }
    .mobile-menu__bottom-bun {
      transform: rotate(-45deg) scaleX(1.3);
    }
  }
}

.mobile-menu__dialog {
  @supports (backdrop-filter: blur(3px)) {
    backdrop-filter: blur(4px) grayscale(1) brightness(1.1);
  }
  margin: 0;
  border: none;
  position: fixed;
  inset: 0;
  inline-size: 100%;
  block-size: 100%;
  max-inline-size: 100%;
  max-block-size: 100%;
  background-color: color-mix(in srgb, var(--color-purple) 95%, transparent);
  padding-block-start: 0;
  padding-inline: var(--page-gutter);
  &::backdrop {
    display: none;
  }
  :where(div) {
    display: flex;
    justify-content: space-between;
    :where(a) {
      padding: var(--top-nav-padding);
    }
  }

  :where(ul) {
    padding-block-start: var(--space-xlarge);
    padding-inline-end: 4px;
    @include mx.flex-column;
    align-items: flex-end;
    gap: var(--space-large);
  }

  :where(a) {
    color: white;
    text-decoration-color: var(--theme-color-primary);
    text-underline-offset: 0.4em;
    text-decoration-thickness: 4px;
    font-size: var(--font-size-large);
    cursor: pointer;
    padding-block-end: 0.4em;
    transition: 200ms ease-in-out;
    &.theme--purple {
      text-decoration-color: var(--color-purple--shade);
    }

    &:hover {
      text-decoration-thickness: 6px;
      text-decoration-color: var(--theme-color-tint);
    }

    // &[aria-current="page"] {
    // text-decoration-color: var(--brand-messenger-color, var(--color-teal-1));
    // }
  }
}
